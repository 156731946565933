import React from 'react'
import Helmet from 'react-helmet'

const
  gridDim = {
    x: 10,
    y: 10
  },
  cellDim = {
    width: '30px',
    height: '30px'
  }

class DungeonsTest extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      grid: this.buildGrid()
    }
  }

  buildGrid(){
    var grid = [];
    for (let i = 0; i < gridDim.x; i++) {
      grid.push([]);
      for (let j = 0; j < gridDim.y; j++) {
        grid[i].push([i,j]);
      }
    }
    return grid;
  }

  render() {

    return (
      <div>

        <Helmet
          title="DUNGEONS"
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
        </Helmet>

          <div>
              {this.state.grid.map(row => <GridRow row={row} />)}
          </div>

      </div>
    )
  }
}

function GridRow(props) {
  const rowStyle = {
    margin: '0',
    marginBottom: '-8px',
    padding: '0'
  }
  return (
    <div style={rowStyle}>
      { props.row.map( id => <Cell id={String(id)} /> )}
    </div>
  )
}

function Cell(props) {
  const cellStyles = {
    backgroundColor: 'white',
    width: cellDim.width,
    height: cellDim.height,
    border: "2px solid gray",
    display: 'inline-block',
    margin: '0',
    marginLeft: '-1px'
  }

  function handleClick(event) {
    var ts = event.currentTarget.style
    ts.backgroundColor = (ts.backgroundColor === 'white'? 'black' : 'white')
  }

  return (
    <div
      onClick={handleClick}
      id={props.id}
      style={cellStyles}
    />
  )
}

export default DungeonsTest
